
import React, { Component } from "react";
import {PageLoader, FooterView, ScrollableView, ImgCmp, BreadCrumbView}  from  './helper.js';
import {get} from './http.js';
import { Redirect, Link } from "react-router-dom";

import SimpleReactValidator from 'simple-react-validator';


class RegisterView extends PageLoader{

    constructor (props){
        super(props);
        this.props = props;
        this.state = {features: [], fields: {
                                            name: true, email: true, 
                                            phone_no: true
                                            }, 
                      navigate: false
                    }
        
        this.validator = new SimpleReactValidator({
            element: message => <div className='error-text'>{message}</div>,
            
        });

        this.set_server_response = this.set_server_response.bind(this); 
        this.setElement = this.setElement.bind(this);
        this.validate_to_payment = this.validate_to_payment.bind(this);
    
    }

    componentDidMount () {

        this.scroll_top();
        let url = '/event/features/' + this.props.location.state.prog_id; 
        get(url, this.set_server_response);
    }

    render (){

        if (this.state.navigate === true){
            return <Redirect to={{
                pathname: "/payments",
                state: {
                    prog_id : this.props.location.state.prog_id,
                    title: this.props.location.state.title,                          
                    program_fee: this.props.location.state.program_fee,
                    minimum_amount: this.props.location.state.min_program_fee,
                    currency: this.props.location.state.cur,                          
                    reg_details: {
                        customer_name: this.state.name,
                        phone_no: this.state.phone_no,
                        address: this.state.address,
                        experience: this.state.experience,
                        email: this.state.email,
                        company: this.state.company
                    }
                  }                                         
                }} 
            />

        }

        return (
        <div>
            <section>
                
                <BreadCrumbView title='Regstration Page' />

                <h3 className="mainfont title_case text-center" style={{marginTop: '4%', marginBottom: '6%'}}> {this.props.location.state.title} </h3>
                        
                <div className="row">
                    

                    <div className="col-md-6">
                        <ImgCmp source={this.props.location.state.prog_img} cls='img-thumbnail img_size' />
                    </div>
                     
                    <div className="col-md-6">
                                                 
                        <h4 className="mainfont font_bold grey"> What To Expect </h4>                        
                        <ul className='icon more_info'>
                            
                           { this.state.features.map(
                                    i => <FeatureList key={i.key} {...i} />
                                )
                            }

                        </ul>

                        <br/>
                        <p className="grey">
                            Click to Download the Curriculum
                            <a href="/download/coursedoc" target="_blank"> Course Outline </a>
                        </p>
                    </div>
                </div>
                <br/><br/>
                <hr style={{borderTop: 'solid 1px #ccc'}} />
                <h4 className="space_top_bottom mainfont text-center"> Profile Information </h4>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="form-group">                            
                            <input type="text" id='name' onChange={this.setElement} 
                                    className={this.state.fields.name? "form-control": 'form-control is-invalid'} placeholder="Full Name"/>  
                            
                            {this.validator.message('name', this.state.name, 'required|alpha_space')}

                          
                        </div>

                        <div className="form-group">                            
                            <input type="int" id='phone_no' onChange={this.setElement} 
                                    className={this.state.fields.phone_no? "form-control": 'form-control is-invalid'} placeholder="Phone No"/>

                            {this.validator.message('phone_no', this.state.phone_no, 'required|min:10')}

                        </div>

                        <div className="form-group"> 
                            <input type="email" id='email' onChange={this.setElement} 
                                    className={this.state.fields.email? "form-control": 'form-control is-invalid'} placeholder="Email Address"/>
                            
                            {this.validator.message('email', this.state.email, 'required|email')}

                        </div>

                        <div className="form-group">                    
                            <textarea placeholder="Contact Address" className="form-control" 
                                      name="address" id="address" onChange={this.setElement} cols="30" rows="5"></textarea>
                        </div>

                        <div className="form-group">                            
                            <input type="text" className='form-control' placeholder="Company Name"/>
                        </div>
                        
                        <div className="form-group">   
                            <br/>                         
                            <p> Do you Have Any Prior Experience?</p>
               
                            <label htmlFor="exp_yes">  
                                <input type="radio" id='exp_yes' onClick={this.setElement} 
                                data-name='yes' name='experience'/>  &nbsp; YES
                            </label>
                            &nbsp;&nbsp;
                            <label htmlFor="exp_no">  
                                <input type="radio" onClick={this.setElement} id='exp_no' 
                                data-name='no' name='experience' />  &nbsp; NO
                            </label>  

                            {this.validator.message('experience_level', this.state.experience, 'required')}

                        </div>
                        
                        <hr style={{borderTop: "solid 1px #aaa"}} />
                        <div className="form-group text-center" style={{marginTop: "10%"}}>               
                            <button className="btn btn-success btn-length" 
                                onClick={this.validate_to_payment}>
                                Register                              
                            </button>
                              &nbsp;&nbsp; 

                            <Link to={{pathname: "/" }} className="btn btn-default btn-length" >                                
                                Cancel                             
                            </Link>
                        </div>
                    </div>
                    
                </div>
                 
            </section>

            <FooterView />
        </div>
        )

    }

    set_server_response(resp) {
        this.setState({features: resp})      
    }

    setElement(event){
        
        if (event.target.name == 'experience'){            
            this.setState({
                [event.target.name]: event.target.getAttribute('data-name')
            });
        }
        else{
            this.setState({[event.target.id]: event.target.value});      
        }

    }

    validate_to_payment(){
        
        this.setState({
          fields: this.validator.fields
        })
        
        if (this.validator.allValid()){
            this.setState({
                          is_error: '',
                          navigate: true});
        }
          
        else {                                         
            this.validator.showMessages();                    
        }

    }
  

}


function FeatureList(data){
    return <li> {data.item} </li>
}


export default RegisterView;
