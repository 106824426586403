import React, { Component } from "react";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import {PageLoader, FooterView, ScrollableView, ImgCmp, htmlstr2react, ImgGallery} from  './helper.js';
import tech_body from  "./tech_stack.js";

import $ from 'jquery';

import playing_img from "../image/whyus.gif";
import code_image from "../image/code.png";
import class_png from "../image/classes.jpg";
import teach_svg from '../image/teaching.png';
import vc_img from "../image/developer.png";
import rv_img from "../image/revenue.png";
import "./lib/countdown-timer.js";
import ads_img from '../image/ads.jpeg';
import focus_img from '../image/our_focus.png';
import interest_img from '../image/our_interests.png';
import {get, post} from './http.js';
import ContactUs from './contactus.js';
import LiveFeedBack from './feedback.js';

const first_body = (
    <div className="row">
        <div className="col-md-6">
            <div className="bo">
                <ImgCmp source={playing_img} cls='rounded' alt='...' />
            </div>
        </div>

        <div className="col-md-6">
            <ul className='rd_txt icon'>
                <li>
                    With over 10 years of experience in tech industry and counting.
                </li>
                <li>
                    We deliver a world standard technology solution for all platform.
                </li>                
                <li>
                    We train and develop individuals professionally.
                </li>
                <li>
                    We can help you achieve your career goals in IT and Software.
                </li>                                
            </ul>
        </div>
    </div>
);



const feedback_body = (
    <div className="row">
        <div className="col-md-12">
            <div className="scroll">
                <div className="text_center">
                    <p>
                        I learnt programming in another dimension. With full practicals
                        The money I paid was worth every penny. The services rendered was
                        phenominal.
                    </p>
                    <p>
                        <strong> Uzodinma Jeffrey </strong> |  2018 july  set
                    </p>
                </div>
                <div className="text_center">
                    <p>
                        I learnt programming in another dimension. With full practicals
                        The money i paid was worth every penny. The services rendered was
                        phenominal.
                    </p>
                    <p>
                        <strong> Uzodinma Jeffrey </strong> |  2018 july  set
                    </p>
                </div>
             </div>
        </div>
    </div>
);


const jumbotron_body = (
    <div className="jumbotron">
        <div className="ecx_scroll">
            <div className="row">
                <div className="col-md-7">
                    <h1 className="display-4 mainfont"> <span>  Welcome to </span> <br/> ECardeX </h1>
                    <p className="lead mainfont type_text"> Where we make software work...</p>
                </div>
                <div className="col-md-5">
                    <ImgCmp source={vc_img} cls='img-rounded' alt='...' />
                </div>
            </div>
            <div className="row">
                <div className="col-md-7">
                    <h1 className="display-4 mainfont"> <span> We Create </span> <br/> Software Apps </h1>
                    <p className="lead mainfont type_text">that will grow your Businesses. </p>
                </div>
                <div className="col-md-5">
                    <ImgCmp source={rv_img} cls='img-rounded' alt='...' />
                </div>
            </div>
            <div className="row">
                <div className="col-md-7">
                    <h1 className="display-4 mainfont"> <span> We Teach </span>  <br/> Software </h1>
                    <p className="lead mainfont type_text"> Development to enthusiasts ... </p>
                </div>
                <div className="col-md-5">
                    <ImgCmp source={teach_svg} cls='img-rounded' alt='...' />
                </div>
            </div>
        </div>

        <hr className="my-4"/>

        <ContactUs />

    </div>
);


let focus_body = (
  <section>
        <div className="row">

            <div className='col-md-6'>   
                <div className='bo'>                 
                    <ImgCmp source={focus_img} cls='rounded' id='ads_img' alt='...' />
                </div>
            </div>
            <div className="col-md-6">
                <ul className='icon'>
                    <li>
                        Software products
                        <p>
                            We create generic software products that will solve specific problems for businesses.
                        </p>
                    </li>
                    <li>
                        Software for Businesses
                        <p> We create custom software for businesses. </p>
                    </li>
                    <li>
                        Software Trainings
                        <p> We train young and vibrant individuals to become better problem solvers with software. </p>
                    </li>
                </ul>
            </div>
        </div>
  </section>
);


class SectionView extends PageLoader {

    render () {

        this.bg_color += ' ' + 'page_sec';

        return (
            <section className={this.bg_color}>
                <div className="row">
                    <div className="col">
                        <h3 className="sec_title mainfont"> {this.props.title} </h3>
                        {this.props.main_body}
                    </div>
                </div>
            </section>
        )
    }

    componentWillUnmount(){}
}


class OurInterest extends PageLoader{


    render() {

        this.bg_color += ' ' + 'page_sec';

        return (

            <section className={this.bg_color} style={{'paddingTop': '5%'}}>
                <div className="row">
                    <div className="col">
                        <h3 className="sec_title mainfont bg_green_text" style={{'color': '#fff', 'marginBottom': '5%'}}> {this.props.title} </h3>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className='bg_green_txt icon'>
                                   <li>                                       
                                       Software Development
                                       <p>   General purpose programming </p>                                   
                                       <p>   Backend Programming </p>
                                       <p>   Frontend Programming </p>
                                       <p>   Desktop Application Development</p>
                                       <p>   Web Application Development  </p>
                                       <p>   Mobile Application Development</p>
                                   </li>
                                   <li>
                                       Software + Electronics
                                       <p>   Internet Of Things (IOT)  </p>
                                       <p>   Embeded Systems Programming  </p>                                       
                                   </li>
                                   <li>
                                       Software + Statistics 
                                       <p>   Data Science  </p>
                                       <p>   Machine Learning  </p>
                                       <p>   Data Analysis  </p>
                                       <p>   Data Mining and Modelling  </p>                                       
                                   </li>
                                </ul>
                            </div>
                            <div className='col-md-6'>                                                 
                                <ImgCmp source={interest_img} cls='rounded' id='ads_img' alt='...' />
                            </div>
                        </div>

                    </div>
                </div>
            </section>           

        )

    }
}

class FeedbackView extends ScrollableView {

    render () {
        this.bg_color += ' ' + 'page_sec';

        return (
            <section className={this.bg_color}>
                <div className="row">
                    <div className="col">
                        <h3 className="sec_title mainfont"> {this.props.title} </h3>
                        {this.props.main_body}
                    </div>
                </div>
            </section>
        )
    }
}


class MiddleView extends PageLoader {

    render () {

        this.bg_color += ' ' + 'middle_view';

        return (
            <section className={this.bg_color}>
                <div className="row">
                    <div className="col-md-8">
                        <div className="highlight">
                            <h3> {this.props.title} </h3>
                        </div>
                    </div>
                    <div className='col-md-2'>  <ContactUs /> </div>
                </div>
            </section>
        )
    }

    componentWillUnmount (){}

}


class GalleryView extends SectionView {

    constructor (props) {
        super(props);
        this.props = props;
        this.bg_color = props.bg || '';
        this.state = {img_data: [], img_count: 0}

        this.load_server_images = this.load_server_images.bind(this);
        this.track_modal = this.track_modal.bind(this);

    }

    componentDidMount() {
        // call the get method to fetch the images from the server 
        
        get('/load/events', this.load_server_images)

    }

    track_modal(){

        $('#pix_display').on(
            'show.bs.modal',
            this.change_display_img
        );
    }

    change_display_img(event) {
        let img = document.getElementById("enlargeimg");
        img.src = event.relatedTarget.src;
    }

    render () {

        this.bg_color += ' ' + 'page_sec';

        return (
            <section className={this.bg_color}>
                <div className="row">
                    <div className="col">
                        <h3 className="sec_title mainfont"> {this.props.title} </h3>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className='rd_txt icon'>
                                    <li>
                                        Our learning environment is structured to enhance focus and concentration.
                                    </li>
                                    <li>
                                        100% Practicals.
                                    </li>
                                    <li>
                                        Our Certificates Are Recognized.
                                    </li>
                                    <li>
                                        We Provide Post Training Support For Free.
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <div id="photos">
                                    
                                    {
                                        this.state.img_data.map(
                                          i => <ImgGallery key={i.key} source={i.src} /> )
                                    }
                                               
                                </div>

                                <div className="modal fade" id="pix_display" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                  <div className="modal-dialog modal-md">
                                      <div className="modal-content">
                                          <img src="" className="rounded" id='enlargeimg' alt=''/>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>             
                    </div>
                </div>
            </section>
        )
    }

    load_server_images(resp) {

        this.setState({
            img_data: resp,
            img_count: resp.length           
        });

        this.track_modal(); // once the images loads, initialize the modal for image display
    
    }

}


class UpComingView extends SectionView {

    constructor(props){
        super(props);
        this.state = {event_body: [], event_count: 0, program_id: null};
        this.process_display = this.process_display.bind(this);
        this.monitor_modal_display = this.monitor_modal_display.bind(this);
        this.get_program_id = this.get_program_id.bind(this);
        this.setup_reminder = this.setup_reminder.bind(this);
        this.close_modal = this.close_modal.bind(this);

    }

    componentDidMount(){
        get('/new_events', this.process_display);        
        this.monitor_modal_display(); // this function will set the event to listen for the modal popup
    }

    render() {

        return (

          <section className="page_sec">
              <div className="row">
                    <div className="col">
                        <h3 className="sec_title mainfont"> Upcoming Training </h3>
                        <a id="upcoming_trainings">&nbsp;</a>
                    </div>
              </div>
              <div className="row">
                      
                  {
                    this.state.event_body.map(
                      i => <DisplayEvent key={i.key} {...i} /> )
                  }   
                     
              </div>
             
             <div className="modal fade" id="set_reminder_md" tabIndex="-1" role="dialog" aria-labelledby="reminderLabel" aria-hidden="true">
                <div className="modal-dialog model-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" style={{"color": "#444", 'fontWeight': 'bold'}}>
                                Set Reminder 
                            </h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-area">
                                <form role="form" autoComplete='off'>
                                    <p className="alert alert-info bold">
                                        This will send you reminders concerning the training that you plan to attend including ` <b> promos </b>` for discounts. Don't worry we won't spam your inbox.
                                    </p> <br/>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="re_name" name="re_name" placeholder="Name" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="re_email" name="re_email" placeholder="Email or Phone" required />
                                    </div> 
                                     
                                </form>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="button" onClick={this.close_modal} className="btn btn-success"> &nbsp;&nbsp; Send &nbsp;&nbsp; </button>
                        </div>
                    </div>
                </div>

            </div>


          </section>

        );
    }

    process_display(resp){        
        this.setState({
            event_body: resp,
            event_count: resp.length
        })
    }
    
    monitor_modal_display(){
        
        $('#set_reminder_md')
            .on(
                'shown.bs.modal',
                this.get_program_id
                );            
    }

    get_program_id(event){

        let obj = event.relatedTarget;
        let event_id = obj.getAttribute('data-event_id');            
        this.setState({program_id: event_id});
    }

    setup_reminder(){
         
        let name = this.getId('re_name');
        let email = this.getId('re_email');

        let pathname = '/event/reminder/'+ this.state.program_id;

        post(pathname, {name: name.value, email: email.value }, (resp) =>{ alert(resp.status)});

        name.value = '';
        email.value = '';

    }

    close_modal(){
        
        $('#set_reminder_md').modal('hide');
        this.setup_reminder();                
    }
}


class DisplayEvent extends Component {

  constructor(props){
      super(props);      
      this.props = props;
      this.display_timer = this.display_timer.bind(this);
      this.set_reminder = this.set_reminder.bind(this);
  }

  componentDidMount() {
      this.display_timer();
  }

  display_timer() {

      var myDate = new Date(this.props.event_date);
      let _idname = '#' + this.props.timer_ele;

      $(_idname).countdown(myDate, function (event) {
          $(this).html(
              event.strftime(
                  '<div class="timer-wrapper"><div class="time">%D</div><span class="text">days</span></div><div class="timer-wrapper"><div class="time">%H</div><span class="text">hrs</span></div><div class="timer-wrapper"><div class="time">%M</div><span class="text">mins</span></div><div class="timer-wrapper"><div class="time">%S</div><span class="text">sec</span></div>'
              )
          );
      });
  }

  render () {

    return ( 
        <div className="col-md-12 small_space_down">
            <div className="row">
                <div className="col-md-6 col-xs-12">
                    <ImgCmp source={this.props.img_src} cls='img-thumbnail' alt='...' />                    
                </div>
                <div className="col-md-6 col-xs-12 text-center">
                    <h3 className='mainfont title_case'> <b> {this.props.title} </b> </h3>
                    <p className='text-highlight title_case'> 
                            Date Scheduled: {this.props.event_date} 
                            <br/> Time : {this.props.event_start_time} prompt 
                    </p>
                    
                    <p>
                        Price : <span className='text-highlight upper_case'> {this.props.cur} {this.props.event_price} </span>
                    </p>
                    <div className="countdown-timer-wrapper">
                        <div className="timer" id={this.props.timer_ele} ></div>
                    </div>
                    <Link to={{
                                pathname: "/register", 
                                state: {
                                        prog_id : this.props.id,
                                        prog_img: this.props.img_src,
                                        title: this.props.title,
                                        cur: this.props.cur,
                                        program_fee: this.props.event_price,
                                        min_program_fee: this.props.minimum_reg_fee
                                    } 
                                }}  className="btn btn-success" >
                            
                            Seat Reservation
                    </Link>  &nbsp;&nbsp;

                    <a href="#" data-toggle="modal" data-event_id={this.props.id} data-target="#set_reminder_md" alt="" className="btn btn-info"> 
                        Set Reminder 
                    </a>
                </div>
            </div>

            
        </div>
    )

  }

  set_reminder() {
        
    prompt("Please specify your name and phone number");

  }

}

class DashBoard extends PageLoader {

    componentDidMount () {

        this.set_active('Home',
                        'nav-item',
                        'nav-item active'
                        );

        this.scroll_top();

        $('.reg_link').show();
        
    }

    render() {

        return (
            <div>
                <ScrollableView main_body={jumbotron_body} selector='.ecx_scroll' total_count={4} />
                <SectionView title='Our Focus' main_body={focus_body} /> 
                
                <OurInterest title='Our Interests' bg='green_bg border_with_padding' />

                <SectionView title='Why Us?' main_body={first_body} />
                <MiddleView title='Lets build your next project.' tag='Contact Us' />
                <GalleryView title='Our Learning Environment' bg='' />                
                <LiveFeedBack />

                {/*<FeedbackView title='Customer Feedback (un-edited)' bg='feedback'
                                            main_body={feedback_body}  selector='.scroll' total_count={8} />
                                */}
                <UpComingView title='Next Training Date' bg="page_sec" tag='Register Now' />

                <FeedbackView title='Our Technology Stack' bg='sec_stack' main_body={tech_body}
                            selector='.scroll2' pages={3} total_count={9} />
                <FooterView />
            </div>
        )
    }

    
    componentWillUnmount(){        
        $('.reg_link').hide();
    }
}


export default DashBoard;
