import React, { Component } from "react";
 
import { Link } from "react-router-dom";
import {get} from './http.js';
import {htmlstr2react, ScrollableView} from './helper.js';



function FeedBackBody(props){
	
	return  (

		<table className="table table-borderless table-striped">
			<tbody>
			<tr style={{color: '#a8a8a8', fontSize: '13px'}}>
				<td> Location : {props.location} </td>
				<td className="text-right"> {props.session} </td>
			</tr>
			<tr>
				<td colSpan="2" className="text-center mainfont">
					{props.name}
				</td>    							
			</tr>
			<tr>				 
				<td colSpan="2">					
					<p className="text-italics">
						<q> 
							{props.comment}					
						</q>
					</p>
				</td>
			</tr>
			<tr>
				<td> 
					{htmlstr2react(props.stars)}					
				</td>
				<td className="text-right">					
					Would you recommend your friends for the trainings?  
					<i> {props.recommend} </i> 
				</td>
			</tr>

			</tbody>
		</table>

	)
}


class LiveFeedBack extends ScrollableView{

	constructor(props){
		
		super(props);
		this.props = props;
        this.selector = '._scroller' ;
        this.perpage = 1;
        this.interval_count = 0;
        		
		this.state = {
						event_body: [], event_count: 0,
						siema_obj: null,
			            interval_obj: null,
			            interval_count: 0,
			            total_count: 15
					};

		this.load_data = this.load_data.bind(this); 
		this.set_response = this.set_response.bind(this); 		
	}

    componentDidMount(){
    	this.load_data();
    }
    
    render(){

    	return (<section>
    		<div className="row">
    			<div className="col-md-12">
    				<h3 className="text-center mainfont space_top_bottom"> Customer Feedback (un-edited)</h3>

    				<div className="row">
    					<div className="table-responsive _scroller">
    						{
    							this.state.event_body.map(
    								i => <FeedBackBody key={i.id} {...i} />
    							)
    						}
    				
    					</div>
    				</div>
    			</div>
    		</div>
    	</section>);
    }

    load_data(){
    	get('/load/feedback', this.set_response)
    }

    set_response(resp){
    	this.setState({event_body: resp, event_count: resp.length})
    	
        this.activate_scroll();
        this.setState({interval_obj: setInterval(this.rotate_scroll.bind(this), 3000) });

    }

}




export default LiveFeedBack;
