
import React, { Component } from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

import slice_png from '../image/slice.png';
import {load_image} from './helper.js';

 
export class MapContainer extends Component {
   

  render() {

  	let lagos = this.props.position.lagos;
  	let abuja = this.props.position.abuja;
    let icon = load_image(slice_png);
    
    return (
      <Map google={this.props.google}
	    style={{width: '95%', height: '100%'}}
	    className={'map'}
	    zoom={6}
	    initialCenter={{lat: abuja.lat, lng:abuja.lng}} >
	  
	  <Marker
	    title={lagos.title}
	    name={lagos.name}
	    position={{lat: lagos.lat, lng:lagos.lng}} 
	    icon={{
	      url: icon,

	      anchor: new google.maps.Point(32,32),
	      scaledSize: new google.maps.Size(60,30)

	    }} />

	  <Marker
	    name={abuja.name}
	    title={abuja.title}
	    position={{lat: abuja.lat, lng:abuja.lng}} 
	    icon={{
	      url: icon,

	      anchor: new google.maps.Point(32,32),
	      scaledSize: new google.maps.Size(60,30)
	    }} />


      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: 'AIzaSyDspkEVx8z6e5m1qIWF960ka-AO3we5VKA'
})(MapContainer)




