
import React from "react";
import ReactDOM from "react-dom";


// import "./bootstrapv4/css/bootstrap-grid.min.css";
// import "./bootstrapv4/css/bootstrap.min.css";
import "./bootstrapv4/js/bootstrap.min.js";
// import "./css/style.css";
import './blockui.js';

import elem from "./pages/home.js";

// import AppRouter from "./pages/navpage.js";

class Init extends React.Component {

    render() {
        return elem;
    }

}

ReactDOM.render(<Init />, document.getElementById('main'));
