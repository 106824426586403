import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import DashBoard from './dashboard_view.js';
import RegView from './registration.js';
import image from '../image/slice.png';

import PaymentView from './payment.js';

import js_img from "../image/javascript.png";
import py from "../image/py.png";
import team_img from "../image/teams.png";
import slice_png from '../image/slice.png';

import {PageLoader, FooterView, ScrollableView, ImgCmp, BreadCrumbView}  from  './helper.js';
import GoogleApiWrapper from  './OfficeAddressMap.js';


const nav = (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link to="/" className="navbar-brand" id="image"> <ImgCmp source={image} alt='logo' /> </Link>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#menu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div id='menu' className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                    <Link to="/" className="nav-link">Home</Link>
                </li> 
                <li className="nav-item">
                    <Link to="/projects" className="nav-link">Projects </Link>
                </li> 
                <li className="nav-item">
                    <Link to="/about" className="nav-link">About Us</Link>
                </li> 
                <li className="nav-item reg_link">
                    <a href="#upcoming_trainings" className='nav-link'> Register </a>
                </li>  
            </ul>
        </div>
    </nav>
);


const HeaderNav = () => {
    return nav;
}


class AboutView extends PageLoader {

    componentDidMount () {
        this.set_active('About Us',
                        'nav-item',
                        'nav-item active'
                        );
    }

    render () {
        return (
            <div>
                <section className="">

                    <BreadCrumbView title='About Us' />
                    
                    <div className="row">
                        <div className='col-md-5'>
                            <ImgCmp source={team_img} alt='..' cls='img-rounded' id='aboutus_img' />
                        </div>
                        <div className='col mainfont'>
                            <p>
                                Ecardex was started out of the need to build applications that will power
                                businesses to grow with the use of software as their major
                                tool for their business operations. <br/> <br/>

                                We are very passionate about the business of software. We enjoy computer programming and converting human tasks into models that the computer can execute. We have been opportuned to integrate and interface with several plaforms for interactivity and data exchange and we have successfully applied this process in Telecoms and VAS industries, Banking and Finance industries, general business applications and simple use case scenarios.
                                <br/> <br/>
                                At Ecardex, we also see a need to train more professionals to be good at making applications
                                 for businesses and things they find interests in doing.

                            </p>
                        </div>

                    </div>
                </section>
                <FooterView/>
            </div>
        );
    }
}


class ProjectView extends PageLoader {

    componentDidMount () {

        this.set_active('Projects',
                        'nav-item',
                        'nav-item active'
                        );
    }


    render () {

        return(
            <div>
                <section className="">

                    <BreadCrumbView title='Projects' />

                    <div className='row space_top_bottom'>
                        <div className="col-md-7">
                            <iframe width="100%" height="380" src="https://www.youtube.com/embed/V42NvgT_M90" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className="col-md-5 mainfont">
                            <h3 className="text-center"> Tic Tac </h3>
                            <p>
                                This code was written during one of our python training programs <br/><br/>
                                <a href="/download/tictac" target="_blank"><i> Download Source Code </i>  </a> 
                            </p>
                            
                            <p> How to execute the file </p>
                            <div className="codearea">
                                
                                <code className="comments">
                                    #  Change directory to the the source python file from your CMD.
                                </code>
                                <code className="codeblk"> 
                                    c:-> cd directory/foldername/
                                </code>
                                
                                <code className="comments">
                                    # confirm the file name is there 
                                </code>
                                <code className='codeblk'>
                                    c:/directory/foldername -> dir 
                                </code>
                                <code className="comments">
                                    # run the application like this (make sure the dependencies are already installed): 
                                </code>
                                <code className="codeblk">
                                    c:/directory/foldername -> python  tictac.py
                                </code>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <FooterView />
            </div>
        )
    }
}


class OfficeAddress extends ImgCmp {
    
    componentDidMount () {
        this.scroll_top();

    }
    
    scroll_top () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
    }

    render () {

        let position = {
                "lagos" : {
                       "lat" : 6.59933,
                       "lng" : 3.3524654,
                       'title': '08 ladipo kuku steet, Opebi Road, Ikeja Lagos',
                       name: 'Lagos Office'
                    },               
            
                "abuja" : {
                       "lat" : 9.087934299999999,
                       "lng" : 7.4967646,
                       'title': '27 limpopo street, Maitama Abuja',
                       name: 'Abuja Office'
                    },
            }

        return(
            <div>
                <section className=""> 

                    <BreadCrumbView title='Office Address' />
                    
                    <div className="row space_top_bottom">
                          
                        <div className="col-md-5">
                            <div className="diviver mainfont">
                                
                                <h3 className='font_bold'> Lagos Address </h3>
                                <p>
                                    08, ladipo kuku street <br/>
                                    Allen Avenue, Ikeja 
                                </p>
                                <br/>

                                <h3 className='font_bold'> Abuja Address </h3>
                                <p>
                                    27, Limpopo Street, <br/>
                                    Maitama
                                </p> 
                                <br/>
                                <p>
                                    Telephone : 0818 035 2096, &nbsp; 0813 789 8903
                                </p>
                                <br/>
                                <p className="gray_text">
                                    <i >
                                        Follow the green logo on the map
                                    </i>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-7 maparea">
                            
                            <GoogleApiWrapper 
                                icon={this.get_src()}
                                position={position}

                            />
                        </div>

                    </div>
                </section>
                <FooterView />
            </div>
        )
    }

}
 

const elem = (

    <Router>
        <div className="container">
            <HeaderNav />
            <Route path="/" exact  component={DashBoard} />
            <Route path="/about" component={AboutView} />            
            <Route path="/projects" component={ProjectView} />
            <Route path="/officeaddress"
                render={(props) => <OfficeAddress {...props} />} 
            /> 
            <Route path='/payments' component={PaymentView}/>
            <Route path="/register"  component={RegView}/>

        </div>
    </Router>
);
    



export default elem;
