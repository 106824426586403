import React, { Component } from "react";
import { Link } from "react-router-dom";
import {get, post} from './http.js';

import SimpleReactValidator from 'simple-react-validator';



class ContactUs extends Component{

    constructor(props){

        super(props);

        this.validator = new SimpleReactValidator({
            element: message => <div className='error-text'>{message}</div>
        });

        this.state = {
            fields: {
                name: true, phone: true,
                subject: true, message: true,
                email: true
            }
        };

        this.setField = this.setField.bind(this);
        this.send_email = this.send_email.bind(this);
        this.clear_field = this.clear_field.bind(this);

    }

    render(){

        return (
    <div className="dropdown">
        <button className="btn btn-success dropdown-toggle" type="button" id="contact_us" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            &nbsp;&nbsp; &nbsp;&nbsp; Contact Us &nbsp;&nbsp; &nbsp;&nbsp;
        </button>
        <div className="dropdown-menu" aria-labelledby="contact_us">
            <a className="dropdown-item" target='blank' href="https://www.instagram.com/ecxtech/"> Via Instagram </a>
            <a className="dropdown-item" target='blank' href="http://wa.me/2348180352096"> Via Whatsapp </a>
            <a className="dropdown-item" data-toggle="modal" href="#cf" aria-labelledby='#_title' > Via Email </a>            
            <Link to="/officeaddress" className="dropdown-item">Office Location </Link>           
        </div>
                
        <div className="modal fade" id="cf" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog model-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" style={{"color": "#444", 'fontWeight': 'bold'}}  id="_title">Contact Us</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                         <div className="form-area">
                            <form role="form" autoComplete='off'>
                                <div className="form-group">
                                    <input type="text" className="form-control" onChange={this.setField}  
                                        id="name" name="name" placeholder="Name"
                                        className={this.state.fields.name? "form-control": 'form-control is-invalid'} />

                                        {this.validator.message('name', this.state.name, 'required|alpha_space')}

                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={this.setField} className="form-control" id="email" 
                                        className={this.state.fields.email? "form-control": 'form-control is-invalid'} 
                                        name="email" placeholder="Email Address" />                            

                                    {this.validator.message('email', this.state.email, 'required|email')}

                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={this.setField} className="form-control" id="phone" name="phone" 
                                    className={this.state.fields.phone? "form-control": 'form-control is-invalid'} 
                                    placeholder="Phone no" />

                                    {this.validator.message('phone', this.state.phone, 'required|min:10')}

                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={this.setField} 
                                        id="subject" name="subject" placeholder="Subject"
                                        className={this.state.fields.subject? "form-control": 'form-control is-invalid'} 
                                         />
                                    
                                    {this.validator.message('subject', this.state.subject, 'required|alpha_space|min:5')}
                                
                                </div>
                                <div className="form-group">
                                    
                                    <textarea className={this.state.fields.message? "form-control": 'form-control is-invalid'}  
                                        type="textarea" id="message" name='message' onChange={this.setField}
                                        placeholder="Message" maxLength="140" rows="7"></textarea>

                                    {this.validator.message('message', this.state.message, 'required|min:20')}

                                    <span><p></p></span>
                                </div>
                            </form>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                        <button type="button" onClick={this.send_email} className="btn btn-success"> &nbsp;&nbsp; Send &nbsp;&nbsp; </button>
                    </div>
                </div>
            </div>
        </div>                
         
    </div>
  );
    }

    setField(event){
        this.setState({[event.target.name]: event.target.value});
    }

    send_email(){

        this.setState({fields: this.validator.fields})

        if (this.validator.allValid()){
            $('#cf').modal('hide');
            post('/email/contact', this.state, (resp)=>{alert(resp.error_msg);})
            this.clear_field();
        }
        else{            
            this.validator.showMessages(); 
        }

        return false;
    }

    clear_field(){

        Object.entries(this.state.fields).forEach(entry => {
            document.getElementById(entry[0]).value = '';                                    
        });

    }

}


export default ContactUs;