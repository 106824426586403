
let base = '';
// base = 'http://ecx.com:5000';  // comment out this line


function get(pathname, callback) {
    // put code here to get to the server
    let url = base + pathname;

    fetch(url).then(
        function (response) {
            return response.json();
        }
    ).then(
        function(result){
            callback(result)
            // return false
        }
    )

    return false
}

function post(pathname, data, callback) {

    // put code here to get to the server
    let url = base + pathname;

    fetch(
          url, {method: 'post', body: JSON.stringify(data)}
          ).then(
        function (response) {
            return response.json();
        }
    ).then(
        function(result){
            callback(result)
        }
    )

    return  false;
}




export {get, post};
