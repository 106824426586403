import React, { Component } from "react";
import ReactDOM from "react-dom";
import Siema from 'siema';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class PageLoader extends Component {

    constructor (props) {
        super(props);
        this.props = props;
        this.bg_color = props.bg || '';      

    }

    render () {
        return this.props.body
    }

    getId(element) {
        return document.getElementById(element)
    }

    contains(search, obj) {

        let output = null;

        if (typeof obj != 'object'){
            obj = obj.split(' ');
        }

        for (var i =0; i < obj.length ;  i++) {

            if (obj[i].trim() == search)
                output = true
        }

        return output != null;
    }


    set_active (contentname, item_cls, active_cls) {

        let header_item = document.getElementsByClassName(active_cls);
        header_item[0].setAttribute('class', item_cls);

        let obj = document.getElementsByClassName(item_cls);

        for (var i=0; i < obj.length; i++) {
            if (this.contains(contentname, [obj[i].textContent])) {
                obj[i].setAttribute('class', active_cls);
                return
            }
        }
    }

    scroll_top () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
    }

}


class FooterView extends PageLoader {
    render() {
        let dt = new Date();
        return (
            <section>
                <div className="row">
                    <div className="col">
                        <hr style={{border: 'solid 1px #ccc'}}/>
                        <div className="footer space_top_bottom">
                            <p className='mainfont'> &#169; Copy right 2018 - { dt.getFullYear()}, ECardeX Ventures </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    componentWillUnmount() {

    }
}


class ScrollableView extends Component {

    constructor(props){
        super(props);
        this.props = props;
        this.selector = props.selector;
        this.bg_color = props.bg || '';
        this.perpage = props.pages || 1;
        this.interval_count = 0;

        // this.rotate_scroll = this.rotate_scroll.bind(this)

        this.state = {
            siema_obj: null,
            interval_obj: null,
            interval_count: 0,
            total_count: props.total_count
        }

    }

    activate_scroll () {

        let siema_ins = new Siema({
            selector: this.selector, // '.ecx_scroll',
            duration: 500,
            easing: 'ease-out',
            perPage: this.perpage,
            startIndex: 0,
            draggable: true,
            loop: true,
            threshold: 20
        });

        this.setState({siema_obj: siema_ins});

        // return siema_ins;
    }

    componentDidMount() {
        this.activate_scroll();
        this.setState({interval_obj: setInterval(this.rotate_scroll.bind(this), 3000) });

    }

    rotate_scroll() {

        this.state.siema_obj.next();

        this.interval_count += 1;

        if (this.interval_count > this.state.total_count ) {
            clearInterval(this.state.interval_obj);
        }

    }

    render () {
        return this.props.main_body;
    }

    componentWillUnmount(){ }

}

class ImgCmp extends Component {

    constructor(props){
        super(props);
        this.props = props;
        
    }

    get_src() {        
        return load_image(this.props.source);
    }

    render() { 
        
        return (<img src={this.get_src()} className={this.props.cls}
                 alt={this.props.alt || ''} id={this.props.id || ''} {...this.props} />);
    }
}


class ImgGallery extends ImgCmp {

    render() { 
        
        return (
            <img src={this.get_src()} data-toggle="modal" data-target="#pix_display" alt=""/>
        )
    }
}


// function to convert strings to react objects

function htmlstr2react (html) {
    return ReactHtmlParser(html)
}


function load_image(image){
    // let src = 'http://' + window.location.host + '/' + image
    // return src
    return 'https://' + window.location.host + '/static/' + image;
}

function BreadCrumbView(props){
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                    <h3> {props.title} </h3>
                </li>
            </ol>
        </nav>
    )
}


export {
        PageLoader, FooterView, ScrollableView, ImgCmp, 
        htmlstr2react, ImgGallery, BreadCrumbView, load_image
        };
