import React, { Component } from "react";
import {PageLoader, FooterView, ScrollableView, ImgCmp} from  './helper.js';

import py_img from "../image/python-logo.png";
import java_img  from  "../image/java.png";
import html5 from "../image/html5.png";
import oracle_img from "../image/oracle_db.png";
import pg_img from "../image/postgresql_db.png";
import react_img from "../image/reactjs-logo.png";
import vue_img from "../image/vuejs.png";
import js_img from "../image/javascript.png";
import mssql_img from "../image/mssqql.jpg";
import sqlite_img from '../image/sqlite370_banner.gif';
import backbone_img from '../image/backbone.png';


function ImgWrapper(props){

    return (        
        <div className='col-xs-12 col-md-3'>
            <ImgCmp {...props} alt={'...'} />            
        </div>        
    )
}

const tech_body = (
    <div className="row">
        <div className="col-md-12">
            <div className="scroll2">
                <ImgWrapper source={py_img} alt={'...'} cls='rounded' />
                <ImgWrapper source={java_img} alt={'...'} cls='rounded small_width' />
                <ImgWrapper source={js_img} alt={'...'} cls='rounded small_width' />
                <ImgWrapper source={html5} alt={'...'} cls='rounded small_width' />
                <ImgWrapper source={oracle_img} alt={'...'} cls='rounded' style={{height: '50px'}} />
                <ImgWrapper source={pg_img} alt={'...'} cls='rounded' />
                <ImgWrapper source={vue_img} alt={'...'} cls='rounded small_width' />
                <ImgWrapper source={react_img} alt={'...'} cls='rounded' />
                <ImgWrapper source={mssql_img} alt={'...'} cls='rounded' style={{width: '150px'}}  />     
                <ImgWrapper source={sqlite_img} alt={'...'} cls='rounded' style={{width: '150px'}}  />     
                <ImgWrapper source={backbone_img} alt={'...'} cls='rounded'  />     
                
            </div>
        </div>
    </div>
);


export default tech_body;
