import React, { Component} from "react";

import {Link } from "react-router-dom";

import SimpleReactValidator from 'simple-react-validator';
import {PageLoader, FooterView, BreadCrumbView}  from  './helper.js';
import {post} from './http.js';


class DisplayPayment extends Component{

	constructor(props){

		super(props);
		this.props = props;  		                         

		this.state = {
			total_amt: this.format_amount(this.props.location.state.program_fee), 
			amt_deposit: null,
			minimum: this.props.location.state.minimum_amount, 
			cur: this.props.location.state.currency,
			customer_name: this.props.location.state.reg_details.customer_name,
			title: this.props.location.state.title,			
			reg_details: this.props.location.state.reg_details
		};

		this.validator = new SimpleReactValidator({
			 
			element: message => <div className='error-text'>{message}</div>,
    		validators: {
		      checkMin: {

		        message: 'The :attribute must be a minimum of ' + this.state.minimum + ' ' + this.state.cur , 
		        rule: (val, params, validator) => {
		        	if (Number(val) < this.state.minimum){
		        		return false;
		        	}
		        	return true;
		        },
		        
		        required: true  // optional
		      },
		   	checkMax: {  // name the rule
		        message: 'The :attribute must not exceed ' + this.state.total_amt + ' ' + this.state.cur,
		        rule: (val, params, validator) => {

		        	if (Number(val) > this.state.total_amt){
		        		return false;
		        	}

		        	return true;
		        },		        
		        required: true  // optional
		      }
		    }


		});


		// the total_amt is meant to come from the props  through the registration page 
	
		this.show_amt_diff = this.show_amt_diff.bind(this);		
		this.send_payment = this.send_payment.bind(this);

	} 

    render () {
		 
        return (
            <div>
                <BreadCrumbView  title='Payments'/>

                <div className="row space_top_bottom">
                	<div className="col-md-8 offset-md-2">
                		<p className="alert alert-info">
                			You are required to atleast make a deposit 
                			of ` <b className='upper_case'>{this.state.cur} {this.state.minimum} </b> ` for your registration to be complete. 
                			On the day of the program, please come with your e-receipt as proof of payment. The fee is <i> <b> refundable </b></i> incase you decide not to attend the program anymore, T&C applies.
                		</p>
						 
 						<table className='table table-striped table-condensed space_top_bottom'>
							<thead>
								<tr>
									<th> Detail </th>
									<th> Value </th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td> Program Name: </td>
									<td className='title_case'> { this.state.title } </td>
								</tr>
								<tr>
									<td> Program Fee: </td>
									<td> {this.state.cur} <span id='total_amt'>{this.state.total_amt}</span>  </td>
								</tr>
								
								<tr>
									<td>
										Amount To Deposit: 
									</td>
									<td>
										<input type="text" id="amt_deposit"  name="amt_deposit" className='form-control' 
											   onChange={this.show_amt_diff} placeholder="Enter Amount To Deposit"/>
										
										{this.validator.message('amount_deposit', this.state.amt_deposit, 'required|integer|checkMin|checkMax')}

									</td>
								</tr>
								<tr>
									<td> Amount To Balance: </td>
									<td> 
										<input type="text" id="amt_balance"  name="amt_balance" 
											className="form-control" readOnly="readonly"  placeholder="Balance Amount" /> 
									</td>
								</tr>
								<tr>
									<td>
										Payment Description:
									</td>
									<td>
										<p id='desc'> </p>
									</td>
								</tr>
								<tr>
									<td colSpan="2" rowSpan="2" className='text-center'>
										<Link to={{
                                			  pathname: "/"}} 
                                			  className="btn btn-default btn-length">                            
					                            Cancel 
					                    </Link> &nbsp;&nbsp; 
										<a href="#" onClick={this.send_payment} className="btn btn-success btn-length"> 
											Pay Now 
										</a>
									</td>
								</tr>
							</tbody>
						</table>
						<p className="text-center" style={{color: '#0d7bca'}}> <i> full payment attracts an automated discount of 10% for payment made before the start date. </i></p>
					</div> 				
 				</div>
            </div>

        )
    }

    get_id(id_name){
    	return document.getElementById(id_name);
    }

    get_value(id_name){
		let name = this.get_id(id_name);
		return Number(this.format_amount(name.value));
	}

	set_description(msg){		
		this.get_id('desc').textContent = msg;
	}

    show_amt_diff(){

		this.remove_error('amt_deposit');
		
		console.log(this.state);

		let amt_deposit = this.get_value('amt_deposit');
 		let diff = this.state.total_amt - amt_deposit;
		
		let name = this.state.customer_name; // temp 
		let msg = 'Registration Fee Deposit';

		if (amt_deposit > this.state.minimum &&  amt_deposit < this.state.total_amt) {
			msg = 'Reg & Part Payment'
		}
		
		else if (amt_deposit == this.state.total_amt){
			msg = 'Complete Fee Payment'
		}

		this.setState({amt_deposit: amt_deposit, payment_desc: msg});
		this.set_description(msg);
		this.get_id('amt_balance').value = this.numberWithCommas(diff);
	}


	error_highlight(id_name){				
		this.get_id(id_name).setAttribute('class', 'form-control is-invalid');
	}

	remove_error(id_name){
		this.get_id(id_name).setAttribute('class', 'form-control');
	}
	
	numberWithCommas(amt) {
    	return amt.toLocaleString();

	}

	format_amount(amt){

		let resp = this.concate_values(amt.split(','));		
		
		return resp;

	}

	concate_values(array_val){

		let resp = '';
		
		for (var i=0; i < array_val.length; i++){ 
			resp += array_val[i];
		}

		return resp;	

	}

	send_payment(event){

		event.preventDefault();
				
		if (this.validator.allValid()) {		   
			// post data to the server 
			// navigate to the payment server 
			// 
			let url = '/payment/initiate/' + this.props.location.state.prog_id;			
 			
			post(url, this.state, (resp)=> {
				console.log(resp);				
				$.unblockUI();	

				if (resp.status == -1 ){
					alert(resp.error_msg);
				}
				else if( resp.status == 0 ){					
					window.location = resp.payment_url;
				}

				return false;
			});

 			
			$.blockUI({ 			
				message: '<p class="text-center"> Please wait... <br/> <div class="loader">Loading...</div></p>', 
				css: { backgroundColor: 'none', color: '#fff', 
					   'font-size': '30px', 'border': 'none'} 
			});

 
		} 
		else {
		    this.validator.showMessages();		    
		    this.error_highlight('amt_deposit');
		}

		

		return false

	}
		

	componentDidMount(){		
        $("html, body").animate({ scrollTop: 0 }, "slow");
	}

}


export default DisplayPayment;

